//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'
import { MDXRenderer } from 'gatsby-plugin-mdx'

//Components
import Layout from '../components/layout'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter } = mdx

  return (
    <Layout>
      <Helmet
        title={`Sidepath  - ${frontmatter.metatitle}`}
        meta={[
          {
            name: 'description',
            content: frontmatter.metatitle,
          },
          {
            name: 'keywords',
            content: frontmatter.metakeywords,
          },
        ]}
      />

      <div class="container container-legal">
        <div class="row">
          <div class="col-lg-12 pt-4 pb-5">
            {/* {frontmatter.title ? <h1>{frontmatter.title}</h1> : null}
            {frontmatter.subtitle ? <h2>{frontmatter.subtitle}</h2> : null} */}
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        metatitle
        metadesc
        metakeywords
        path
        title
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1800, maxHeight: 500, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
